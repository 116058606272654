import CartModal from './CartModal';
import { AppState } from '../../../../state/createStore';
import { connect } from 'react-redux';
import { cartSummarySelector } from '../../../../state/selectors/cartSummarySelector';
import { allBusinessNotificationsSelectorByDispatchTime } from '../../../../state/selectors/businessNotificationSelector';
import { bindActionCreators, Dispatch } from 'redux';
import { openModal } from '../../../../state/session/session.actions';
import {
  clickGoToCheckout,
  setIsCalculatingServiceFees,
  setCalculatedFees,
} from '../../../../state/checkout/checkout.actions';
import { updateCalculatedFeesDecorator } from '../../../../core/calcServiceFees';
import { getCalculatedFeesParamsSelector } from '../../../../state/selectors/calculatedFeesSelector';

function mapStateToProps(state: AppState, ownProps: any) {
  const { displayableOrderItems, totalOrderPrice, priceComponents } = cartSummarySelector(state);
  const address = state.checkout.dispatch.type === 'delivery' ? state.checkout.dispatch.address : undefined;
  const calculatedFeesParams = getCalculatedFeesParamsSelector(state);

  return {
    itemCount: state.cart.orderItems.length,
    displayableOrderItems,
    siteIsTemplate: state.platformParams?.instance?.siteIsTemplate,
    businessNotifications: allBusinessNotificationsSelectorByDispatchTime(state).map((bnd) => bnd.notification),
    isMobile: state.platformParams.isMobile,
    isCalculatingServiceFees: state.checkout.isCalculatingServiceFees,
    address,
    dispatch: state.checkout.dispatch,
    totalOrderPrice,
    isCurbside: state.checkout.dispatch.type === 'takeout' && state.checkout.dispatch.isCurbside,
    subtotal: priceComponents.subtotal,
    currentLocationId: state.session.restaurant.currentLocationId,
    updateCalculatedFees: updateCalculatedFeesDecorator(calculatedFeesParams),
    ...ownProps,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      openModal,
      clickGoToCheckout,
      setIsCalculatingServiceFees,
      setCalculatedFees,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CartModal);
