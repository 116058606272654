import React from 'react';
import { useHistory } from 'react-router-dom';
import { useBi, useEnvironment, useExperiments, useTranslation, useFedopsLogger } from '@wix/yoshi-flow-editor';
import DrawerLayout from '../DrawerLayout';
import Cart from '../Cart';
import { ButtonPriority as PRIORITY } from 'wix-ui-tpa';
import dataHooks from '../../data-hooks';
import styles from './CartModal.scss';
import CartTitle from '../CartTitle';
import { Modals, RouteUrls } from '../../../../core/constants';
import { scroller } from 'react-scroll';
import { DRAWER_LAYOUT_CONTENT_CONTAINER } from '../DrawerLayout/DrawerLayout';
import Button from '../Button';
import NotificationBar from '../NotificationBar';
import Text from '../../core-components/Text';
import {
  BusinessNotifications,
  DisplayableOrderItem,
  Dispatch,
  DispatchType,
  Address,
  isAddress,
  Restaurant,
} from '@wix/restaurants-client-logic';
import { OpenModalPayload } from '../../../../state/session/session.actions.types';
import { isOrderItemListValid } from './CartModal.helper';
import ErrorBanner from '../ErrorBanner';
import useMinOrderPriceDetails from '../../../../core/hooks/useMinOrderPriceDetails';
import {
  previewOnboardingModalLoad,
  liveSiteMinimumOrderError,
  goToCheckout,
  cartDiscard,
} from '@wix/bi-logger-olo-client/v2';
import {
  SetIsCalculatingServiceFeesPayload,
  SetCalculatedFeesPayload,
} from '../../../../state/checkout/checkout.actions.types';
import { getVirtualDispatchTypeFromDispatch } from '../../../../core/logic/dispatchLogic';
import { UpdateCalculatedFeesParams } from '../../../../core/calcServiceFees';
import { CalculatedFee } from '@wix/ambassador-service-fees-rules/types';

export interface CartModalProps {
  itemCount: number;
  onRequestClose: () => void;
  displayableOrderItems: DisplayableOrderItem[];
  businessNotifications: BusinessNotifications[];
  isMobile: boolean;
  mobileCheckoutMode?: boolean;
  openModal: (payload: OpenModalPayload) => void;
  dispatch: Dispatch;
  totalOrderPrice: number;
  address?: Address;
  siteIsTemplate?: boolean;
  clickGoToCheckout: () => void;
  setCalculatedFees: (payload: SetCalculatedFeesPayload) => void;
  setIsCalculatingServiceFees: (payload: SetIsCalculatingServiceFeesPayload) => void;
  isCalculatingServiceFees?: boolean;
  isCurbside?: boolean;
  subtotal: number;
  currentLocationId?: string;
  updateCalculatedFees: (
    params: UpdateCalculatedFeesParams,
  ) => Promise<{ calculatedFees?: CalculatedFee[] | undefined; errors?: string[] | undefined }>;
}

const shouldShowNotification = (
  notifications: BusinessNotifications[],
  isPreview: boolean,
  siteIsTemplate?: boolean,
  templatesDemoExperimentEnabled?: boolean,
) => {
  const OlOunavailable = notifications.includes(BusinessNotifications.OlOunavailable);
  const NotPremium = notifications.includes(BusinessNotifications.NotPremium);
  const NoPaymentMethods = notifications.includes(BusinessNotifications.NoPaymentMethods);
  const NoPhysicalLocation = notifications.includes(BusinessNotifications.NoPhysicalLocation);

  const shouldShowNotificationWithoutTemplate =
    (OlOunavailable || NotPremium || NoPaymentMethods || NoPhysicalLocation) && !isPreview;

  return templatesDemoExperimentEnabled
    ? shouldShowNotificationWithoutTemplate && !siteIsTemplate
    : shouldShowNotificationWithoutTemplate;
};

const isCheckoutBlockedByBusinessNotification = (notifications: BusinessNotifications[], isPreview: boolean) => {
  const RestaurantClosed = notifications.includes(BusinessNotifications.RestaurantClosed);
  const OnlyFutureOrders = notifications.includes(BusinessNotifications.OnlyFutureOrders);

  return (shouldShowNotification(notifications, isPreview) || RestaurantClosed || OnlyFutureOrders) && !isPreview;
};

const isCheckoutBlockedByMinimumOrderPrice = (
  dispatchType: DispatchType,
  isMinOrderPriceMet: boolean,
  address?: Address,
) => {
  return (
    (dispatchType === 'delivery' && !isMinOrderPriceMet && isAddress(address)) ||
    (dispatchType === 'takeout' && !isMinOrderPriceMet)
  );
};

function useEnvironmentSafe() {
  try {
    return useEnvironment();
  } catch (e) {
    return { isPreview: false };
  }
}

const CartModal: React.FC<CartModalProps> = ({
  itemCount,
  onRequestClose,
  displayableOrderItems,
  businessNotifications,
  isMobile,
  mobileCheckoutMode,
  openModal,
  dispatch,
  totalOrderPrice,
  address,
  clickGoToCheckout,
  setCalculatedFees,
  setIsCalculatingServiceFees,
  isCalculatingServiceFees,
  siteIsTemplate,
  isCurbside,
  subtotal,
  currentLocationId,
  updateCalculatedFees,
}) => {
  const biLogger = useBi();
  const fedopsLogger = useFedopsLogger();
  const history = useHistory();
  const { experiments } = useExperiments();
  const { isPreview } = useEnvironmentSafe();
  const { t } = useTranslation();
  const { isMinOrderPriceMet, displayableAmountLeft, minOrderPrice } = useMinOrderPriceDetails();
  const templatesDemoExperimentEnabled = experiments.enabled('specs.restaurants.templatesDemo');
  const isRemoveFeesFromCartExperimentEnabled = experiments.enabled('specs.restaurants.removeFeesFromCart');
  const dispatchType = dispatch.type;

  const { isValid, firstInvalidItem: errorItemIndex } = isOrderItemListValid(displayableOrderItems);
  const hasError = !isValid;
  const shouldDisableGoToCheckoutWithoutTemplate =
    !itemCount ||
    hasError ||
    isCheckoutBlockedByBusinessNotification(businessNotifications, isPreview) ||
    isCheckoutBlockedByMinimumOrderPrice(dispatchType, isMinOrderPriceMet, address);
  const shouldDisableGoToCheckout = templatesDemoExperimentEnabled
    ? shouldDisableGoToCheckoutWithoutTemplate && !siteIsTemplate
    : shouldDisableGoToCheckoutWithoutTemplate;

  const calculateServiceFees = async () => {
    let calcServiceFeesHasError = false;
    const { calculatedFees, errors } = await updateCalculatedFees({
      fedopsLogger,
      subtotal,
      setIsCalculatingServiceFees,
      experiments,
      locationId: currentLocationId,
      isCurbside,
      dispatch: getVirtualDispatchTypeFromDispatch(dispatch),
    });
    calcServiceFeesHasError = !!(errors && errors.length > 0);
    if (calcServiceFeesHasError) {
      // to-do
    }
    if (!calcServiceFeesHasError && calculatedFees !== undefined) {
      setCalculatedFees({ calculatedFees });
    }
  };

  const onClickContinueBtn = async () => {
    if (hasError) {
      scroller.scrollTo(dataHooks.cartDishItem(errorItemIndex), {
        smooth: 'easeInOutCubic',
        duration: 200,
        offset: -50,
        containerId: DRAWER_LAYOUT_CONTENT_CONTAINER,
      });
      return;
    }
    if (isPreview && businessNotifications.includes(BusinessNotifications.NoPaymentMethods)) {
      openModal({ modal: Modals.CONNECT_PAYMENT_MODAL });
      biLogger.report(previewOnboardingModalLoad({ type: 'Payments' }));
      return;
    } else if (isPreview && businessNotifications.includes(BusinessNotifications.NotPremium)) {
      openModal({ modal: Modals.UPGRADE_TO_PREMIUM_MODAL });
      biLogger.report(previewOnboardingModalLoad({ type: 'Premium' }));
      return;
    }
    if (!isMinOrderPriceMet) {
      biLogger.report(
        liveSiteMinimumOrderError({
          price: totalOrderPrice,
          minimumOrder: minOrderPrice,
          pageName: 'Checkout',
          dispatchType,
        }),
      );
    }
    await calculateServiceFees();
    onRequestClose();
    clickGoToCheckout();
    biLogger.report(goToCheckout({ totalItemsCount: itemCount, abTestSum: 1 }));
    history.push(RouteUrls.CHECKOUT_FLOW);
  };

  const footer = !mobileCheckoutMode ? (
    <div className={styles.footer}>
      <NotificationBar
        shouldShowNotification={() =>
          shouldShowNotification(businessNotifications, isPreview, siteIsTemplate, templatesDemoExperimentEnabled)
        }
        hasErrorState
        className={styles.notificationBarCart}
        ShouldBeRenderedByDispatchTime
      />
      {!isMinOrderPriceMet && (
        <ErrorBanner
          value={t('checkout_main_order_minprice_errormessage', { amount: displayableAmountLeft })}
          data-hook={dataHooks.cartMinOrderPriceErrorBanner}
        />
      )}
      <Button
        upgrade
        fullWidth
        disabled={shouldDisableGoToCheckout}
        priority={PRIORITY.primary}
        data-hook={dataHooks.cartGoToCheckoutButton}
        onClick={onClickContinueBtn}
        loading={isCalculatingServiceFees}
      >
        <Text typography="p2-m-colorless">{t('cart_cta_checkout')}</Text>
      </Button>
    </div>
  ) : null;

  const cartProps = {
    disabled: shouldDisableGoToCheckout,
    hideTotal: true,
    hideTaxAndFees: isRemoveFeesFromCartExperimentEnabled,
    hideMinOrderPrice: mobileCheckoutMode || (!address && dispatchType === 'delivery'),
    isCartImmutable: mobileCheckoutMode,
    selectedAddressOption: address,
  };

  return (
    <DrawerLayout
      header={<CartTitle typograghy="header-xs" />}
      footer={footer}
      onCloseClick={() => {
        biLogger.report(cartDiscard({}));
        onRequestClose();
      }}
      hook={dataHooks.cartModal}
      isMobile={isMobile}
    >
      <Cart {...cartProps} />
    </DrawerLayout>
  );
};

export default CartModal;
