import { CalculatedFee, CalculateServiceFeesRequest, Type } from '@wix/ambassador-service-fees-rules/types';
import { ServiceFeesRules } from '@wix/ambassador-service-fees-rules/http';
import { VirtualDispatchType } from '@wix/restaurants-client-logic/dist/types/types/Dispatch';
import { Experiments } from '@wix/yoshi-flow-editor';
import { SetIsCalculatingServiceFeesPayload } from '../state/checkout/checkout.actions.types';
import { RestaurantLocation } from '@wix/ambassador-restaurant-locations/types';
import { AppState } from '../state/createStore';

const CALC_FEES_REQUEST_LABEL = 'restaurants';
export type UpdateCalculatedFeesParams = {
  experiments: Experiments;
  locationId?: string;
  isCurbside?: boolean;
  subtotal: number;
  fedopsLogger: any;
  dispatch: VirtualDispatchType;
  setIsCalculatingServiceFees?: (payload: SetIsCalculatingServiceFeesPayload) => void;
};

const getShippingInfoType = (dispatchType: VirtualDispatchType, isCurbside?: boolean) => {
  if (isCurbside) {
    return Type.CURBSIDE_PICKUP;
  }

  switch (dispatchType) {
    case 'delivery':
      return Type.DELIVERY;
    case 'dine-in':
      return Type.DINE_IN;
    case 'takeout':
      return Type.PICKUP;
    default:
      return Type.UNSPECIFIED_FULFILLMENT_TYPE;
  }
};

const getRestLocationId = ({
  locationId,
  restaurantLocations,
}: {
  locationId?: string;
  restaurantLocations: RestaurantLocation[];
}) => {
  const restaurantLocation = restaurantLocations.find(
    (restLocation) => restLocation.location?.locationId === locationId,
  );
  return restaurantLocation?.id;
};

export const createCalcFeesRequest = ({
  currency,
  isCurbside,
  subtotal,
  locale,
  isMobile,
  dispatch,
  locationId,
  restaurantLocations,
}: {
  currency: string;
  isCurbside?: boolean;
  subtotal: number;
  locale: string;
  isMobile: boolean;
  dispatch: VirtualDispatchType;
  locationId?: string;
  restaurantLocations: RestaurantLocation[];
}): CalculateServiceFeesRequest => {
  const shippingInfoType = getShippingInfoType(dispatch, isCurbside);
  const localeObj = locale.split('_');
  const restLocationId = getRestLocationId({ locationId, restaurantLocations });

  return {
    label: CALC_FEES_REQUEST_LABEL,
    order: {
      locale: {
        country: localeObj[1],
        languageCode: localeObj[0],
      },
      currency,
      locationId: restLocationId,
      platform: {
        value: isMobile ? 'MOBILE_SITE' : 'SITE',
      },
      priceSummary: {
        subtotal: (subtotal / 100).toFixed(2),
      },
      shippingInfo: {
        logistics: {
          type: shippingInfoType,
        },
      },
    },
  };
};

export const updateCalculatedFeesDecorator = ({
  isMobile,
  signedInstance,
  locale,
  currency,
  restaurantLocations,
}: {
  isMobile: boolean;
  signedInstance?: string;
  currency: string;
  locale: string;
  restaurantLocations: RestaurantLocation[];
}) => {
  return async ({
    experiments,
    locationId,
    isCurbside,
    subtotal,
    fedopsLogger,
    setIsCalculatingServiceFees,
    dispatch,
  }: UpdateCalculatedFeesParams): Promise<{
    calculatedFees?: CalculatedFee[] | undefined;
    errors?: string[] | undefined;
  }> =>
    updateCalculatedFees({
      experiments,
      locationId,
      currency,
      locale,
      restaurantLocations,
      signedInstance,
      isMobile,
      isCurbside,
      subtotal,
      fedopsLogger,
      dispatch,
      setIsCalculatingServiceFees,
    });
};

export const updateCalculatedFees = async ({
  experiments,
  signedInstance,
  currency,
  locationId,
  isCurbside,
  subtotal,
  fedopsLogger,
  setIsCalculatingServiceFees,
  locale,
  isMobile,
  dispatch,
  restaurantLocations,
}: {
  experiments: Experiments;
  signedInstance?: string;
  currency: string;
  locale: string;
  locationId?: string;
  isCurbside?: boolean;
  isMobile: boolean;
  subtotal: number;
  fedopsLogger: any;
  dispatch: VirtualDispatchType;
  setIsCalculatingServiceFees?: (payload: SetIsCalculatingServiceFeesPayload) => void;
  restaurantLocations: RestaurantLocation[];
}): Promise<{ calculatedFees?: CalculatedFee[] | undefined; errors?: string[] | undefined }> => {
  const result: {
    calculatedFees?: CalculatedFee[];
    errors?: string[];
  } = {
    calculatedFees: undefined,
    errors: undefined,
  };

  const isServiceFeeExperimentEnabled = experiments.enabled('specs.restaurants.isServiceFeeEnabled-olo-client-dev');
  if (!isServiceFeeExperimentEnabled) {
    return {};
  }

  const headers = { Authorization: signedInstance };
  const calculateServiceFeesApi = ServiceFeesRules('/_api/restaurants').ServiceFeesCalculate()(headers);
  const request = createCalcFeesRequest({
    dispatch,
    isMobile,
    subtotal,
    isCurbside,
    currency,
    locationId,
    locale,
    restaurantLocations,
  });

  try {
    setIsCalculatingServiceFees && setIsCalculatingServiceFees({ isCalculating: true });

    fedopsLogger.interactionStarted('calculate-service-fees');
    const { calculatedFees } = await calculateServiceFeesApi.calculateServiceFees(request);
    fedopsLogger.interactionEnded('calculate-service-fees');
    setIsCalculatingServiceFees && setIsCalculatingServiceFees({ isCalculating: false });

    result.calculatedFees = calculatedFees;
  } catch (e) {
    console.error('Could not calculate service fees', e);
    result.errors = ['error'];
  }
  return result;
};
