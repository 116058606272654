import React, { useEffect, useRef } from 'react';
import styles from './CartSubtotal.scss';
import Text from '../../core-components/Text';
import dataHooks from '../../data-hooks';
import { useTranslation, useFedopsLogger, useExperiments } from '@wix/yoshi-flow-editor';
import { ServiceFee, PriceComponents, VirtualDispatchType } from '@wix/restaurants-client-logic';
import CartTaxAndFees from '../CartTaxAndFees';
import { UpdateCalculatedFeesParams } from '../../../../core/calcServiceFees';
import { CalculatedFee } from '@wix/ambassador-service-fees-rules/types';
import { SetCalculatedFeesPayload } from '../../../../state/checkout/checkout.actions.types';

export interface CartSubtotalProps {
  subtotal?: string;
  tax?: string;
  serviceFees?: ServiceFee[];
  taxesAndServiceFeesTotalAmount?: string;
  deliveryFee?: string;
  tip?: string;
  total?: string;
  showTotal?: boolean;
  showTaxAndFees?: boolean;
  isEstimatedDeliveryFee?: boolean;
  minOrderPrice?: string;
  setCalculatedFees: (payload: SetCalculatedFeesPayload) => void;
  priceComponents: PriceComponents;
  locale: string;
  currency: string;
  isMobile: boolean;
  dispatchType: VirtualDispatchType;
  locationId?: string;
  isCurbside: boolean;
  signedInstance?: string;
  isCheckoutPage?: boolean;
  updateCalculatedFees: (
    params: UpdateCalculatedFeesParams,
  ) => Promise<{ calculatedFees?: CalculatedFee[] | undefined; errors?: string[] | undefined }>;
}

const CartSubtotal: React.FC<CartSubtotalProps> = ({
  subtotal,
  tax,
  serviceFees,
  taxesAndServiceFeesTotalAmount,
  deliveryFee,
  tip,
  total,
  showTotal,
  showTaxAndFees,
  isEstimatedDeliveryFee,
  minOrderPrice,
  setCalculatedFees,
  priceComponents,
  dispatchType,
  locationId,
  isCurbside,
  isCheckoutPage,
  updateCalculatedFees,
}) => {
  const { t } = useTranslation();
  const { experiments } = useExperiments();

  const fedopsLogger = useFedopsLogger();
  const shouldRenderDeliveryFee = showTaxAndFees && (deliveryFee || isEstimatedDeliveryFee);
  const shouldRenderTaxAndFees = showTaxAndFees && (tax || serviceFees?.length);
  const didMountRef = useRef(false);

  useEffect(() => {
    const calculateServiceFees = async () => {
      let calcServiceFeesHasError = false;
      const { calculatedFees, errors } = await updateCalculatedFees({
        fedopsLogger,
        subtotal: priceComponents.subtotal,
        experiments,
        locationId,
        isCurbside,
        dispatch: dispatchType,
      });
      calcServiceFeesHasError = !!(errors && errors.length > 0);
      if (calcServiceFeesHasError) {
        // to-do
      }
      if (!calcServiceFeesHasError && calculatedFees !== undefined) {
        setCalculatedFees({ calculatedFees });
      }
    };
    if (didMountRef.current && isCheckoutPage) {
      calculateServiceFees();
    }
    didMountRef.current = true;
  }, [subtotal]);

  return (
    <div className={styles.wrapper} data-hook={dataHooks.cartSubtotalWrapper}>
      <div className={minOrderPrice ? styles.rowMinPrice : styles.row}>
        <Text typography="p2-m" className={styles.title}>
          {t('cart_summary_subtotal')}
        </Text>
        <Text typography="p2-m" className={styles.price} data-hook={dataHooks.cartSubtotalSubtotal}>
          {subtotal}
        </Text>
      </div>
      {Boolean(minOrderPrice) && (
        <div className={styles.row}>
          <Text data-hook={dataHooks.cartSubtotalMinOrder} typography="p2-s-secondary">
            {t('Order_Online_MinimumOrder_Amount_Label', { amount: minOrderPrice })}
          </Text>
        </div>
      )}
      {!showTaxAndFees && (
        <div className={styles.row}>
          <Text data-hook={dataHooks.cartSubtotalTaxAndFeesMsg} typography="p2-s">
            {t('cart_summary_no_tax_and_fees')}
          </Text>
        </div>
      )}
      {shouldRenderTaxAndFees ? (
        <CartTaxAndFees
          tax={tax}
          serviceFees={serviceFees}
          taxesAndServiceFeesTotalAmount={taxesAndServiceFeesTotalAmount}
        />
      ) : null}
      {shouldRenderDeliveryFee && (
        <div className={styles.row}>
          <Text typography="p2-m" className={styles.title}>
            {t('checkout_main_order_delivery_fee')}
          </Text>
          <Text typography="p2-m" className={styles.price}>
            {isEstimatedDeliveryFee ? (
              <Text data-hook={dataHooks.cartSubtotalDeliveryFeeFromText} typography="p2-s-secondary">
                {t('checkout_main_order_delivery_fee_varies_label')}
              </Text>
            ) : (
              <span data-hook={dataHooks.cartSubtotalDeliveryFee}>{deliveryFee}</span>
            )}
          </Text>
        </div>
      )}

      {tip && (
        <div className={styles.row}>
          <Text typography="p2-m" className={styles.title}>
            {t('cart_summary_tip')}
          </Text>
          <Text typography="p2-m" className={styles.price} data-hook={dataHooks.cartSubtotalTip}>
            {tip}
          </Text>
        </div>
      )}

      {showTotal && (
        <div className={`${styles.row} ${styles.total}`}>
          <Text typography="p2-l" className={styles.title}>
            {t('cart_summary_total')}
          </Text>
          <Text typography="p2-l" className={styles.price} data-hook={dataHooks.cartSubtotalTotal}>
            {total}
          </Text>
        </div>
      )}
    </div>
  );
};

CartSubtotal.displayName = 'CartSubtotal';

export default CartSubtotal;
